<template>
    <div class="personalInfo">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="手机号码" prop="telephone">
                <el-input v-model="ruleForm.telephone" autocomplete="off" disabled></el-input>
            </el-form-item>
            <el-form-item label="用户昵称" prop="nickName">
                <el-input v-model="ruleForm.nickName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="邮件地址" prop="email">
                <el-input v-model="ruleForm.email" autocomplete="off"></el-input>
            </el-form-item>
            <!-- <el-form-item label="登陆密码" prop="password" required>
                <el-input v-model="ruleForm.password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="confirmPwd" required>
                <el-input v-model="ruleForm.confirmPwd" autocomplete="off"></el-input>
            </el-form-item> -->
            <el-form-item label="用户头像" prop="avatar">
                <el-upload
                    class="avatar-uploader"
                    :action="action"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                    :data="otherParam">
                    <img v-if="ruleForm.avatar" :src="ruleForm.avatar" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
            </el-form-item>
            <el-form-item label="性别" prop="gender">
                <el-radio-group v-model="ruleForm.gender">
                    <el-radio label="2">保密</el-radio>
                    <el-radio label="0">男</el-radio>
                    <el-radio label="1">女</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="出生年月">
                    <!-- <el-date-picker
                    v-model="ruleForm.year"
                    type="year"
                    placeholder="选择年">
                    </el-date-picker>

                    <el-date-picker
                    v-model="ruleForm.month"
                    type="month"
                    placeholder="选择月">
                    </el-date-picker> -->

                    <el-date-picker
                    v-model="ruleForm.birthday"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                    </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
                <el-button @click="resetForm('ruleForm')">重置</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import {getUploadPolicy,editUserInfo} from "@/axios/api"
export default {
    data(){
        return{
            otherParam: {
                policy: '',
                signature: '',
                key: '',
                ossaccessKeyId: '',
                dir: '',
                host: '',
                // callback:'',
            },
            ruleForm: {
                telephone: '',
                nickName: '',
                email:"",
                password:"",
                confirmPwd:"",
                avatar:"",
                gender: '',
                year:"",
                month:"",
                birthday:"",
            },
            rules:{
                telephone:[
                    { required: true, message: '请输入电话号码', trigger: 'blur' },
                ],
                password:[
                    { required: true, message: '请输入登录密码', trigger: 'blur' },
                ],
                confirmPwd:[
                    { required: true, message: '请再次输入密码', trigger: 'blur' },
                ],
            },
            action:"https://ai-smart-mall.oss-cn-chengdu.aliyuncs.com/",
            action:"",
        }
    },
    methods: {
        handleAvatarSuccess(res,file){
            // // console.log(res)
            // // console.log(file)
            // this.ruleForm.avatar = URL.createObjectURL(file.raw);
            this.ruleForm.avatar = _uploadRoot+this.otherParam.dir+file.name;
            // // console.log(this.ruleForm.avatar)
        },
        beforeAvatarUpload(file){
            // // console.log(file)
            this.otherParam.key=this.otherParam.dir+file.name

            return this.gainUploadPolicy();
        },
        // 获取上传参数
        gainUploadPolicy(){
            return new Promise((resolve,reject)=>{
                getUploadPolicy().then(res=>{
                    // console.log("上传参数",res);
                    if(res.code==200){
                        this.otherParam.policy=res.data.policy;
                        this.otherParam.signature=res.data.signature;
                        this.otherParam.ossaccessKeyId=res.data.accessId;
                        this.otherParam.policy=res.data.policy;
                        this.otherParam.dir=res.data.dir;
                        this.otherParam.host=res.data.host;

                        resolve()
                    }else{
                        reject()
                    }
                })
            })
        },
        // 保存信息
        submitForm(ruleForm){
            // console.log(this.ruleForm);
            var obj=JSON.parse(JSON.stringify(this.ruleForm));
            delete obj.year;
            delete obj.month;
            delete obj.confirmPwd;
            // console.log(obj)
            editUserInfo(obj).then(res=>{
                // console.log("修改用户信息结果",res);
                if(res.code==200){
                    this.$message.success(res.msg||res.message);
                }else{
                    this.$message(res.msg||res.message);
                }
            })
        }
    },
    created() {
        this.action=_uploadRoot;

        this.userInfo=JSON.parse(localStorage.AOGEYA_userInfo);
        this.ruleForm.telephone=this.userInfo.telephone;
        this.ruleForm.nickName=this.userInfo.nickName;
        this.ruleForm.email=this.userInfo.email;
        this.ruleForm.gender=this.userInfo.gender;
        this.ruleForm.avatar=this.userInfo.avatar;
        this.ruleForm.birthday=this.userInfo.birthday;

        this.gainUploadPolicy();
    },
}
</script>

<style lang="less" scoped>
.personalInfo{
    .el-form{
        /deep/.el-form-item__content{
            .el-input{
                width: 237px!important;
            }
            .avatar-uploader .el-upload {
                border: 1px dashed #d9d9d9;
                border-radius: 6px;
                cursor: pointer;
                position: relative;
                overflow: hidden;
            }
            .avatar-uploader .el-upload:hover {
                border-color: #409EFF;
            }
            .avatar-uploader-icon {
                font-size: 28px;
                color: #8c939d;
                width: 88px;
                height: 88px;
                line-height: 88px;
                text-align: center;
            }
            .avatar {
                width: 88px;
                height: 88px;
                display: block;
            }
        }
    }
}
</style>